/*!
    * Start Bootstrap - SB Admin v7.0.4 (https://startbootstrap.com/template/sb-admin)
    * Copyright 2013-2022 Start Bootstrap
    * Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-sb-admin/blob/master/LICENSE)
    */
    // 
// Scripts
// 

window.addEventListener('DOMContentLoaded', event => {

    // Toggle the side navigation
    const sidebarToggle = document.body.querySelector('#sidebarToggle');

    const overlayContent = document.querySelector('#overlay_content');
    // const getContentSidebarTogglePseudoElement = getContentSidebarToggle.pseudo('::before')

    if (sidebarToggle) {
        // Uncomment Below to persist sidebar toggle between refreshes
        // if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
        //     document.body.classList.toggle('sb-sidenav-toggled');
        // }
        sidebarToggle.addEventListener('click', event => {
            event.preventDefault();
            document.body.classList.toggle('sb-sidenav-toggled');
            localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
        });

        overlayContent.addEventListener('click', event => {
            event.preventDefault();
            document.body.classList.toggle('sb-sidenav-toggled');
            localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
        });
    }

    // toggle theme 
    const themeSwitcher = document.body.querySelector('#themeSwitcher');
    if (themeSwitcher) {
        if (localStorage.getItem('sb-themeswitcher') === 'light') {
            document.getElementById('sidenavAccordion').classList.toggle('sb-sidenav-light');
            themeSwitcher.classList.toggle('text-white');
        }
        themeSwitcher.addEventListener('click', event => {
            event.preventDefault();
            document.getElementById('sidenavAccordion').classList.toggle('sb-sidenav-light');
            themeSwitcher.classList.toggle('text-white');
            localStorage.setItem('sb-themeswitcher', localStorage.getItem('sb-themeswitcher') === 'light' ? 'dark' : 'light');
        });
    }

});